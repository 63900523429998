<template lang="pug">
.vc-kolcenter-campaign-detail-view
  k-form-card(:title="promoterCampaign.name")
    template(slot="body")
      k-field(
        style-type="kolcenter"
        :label="attributeLocaleText('promoter/campaign_share_ship', 'state')"
        horizontal
      )
        .wrapper
          campaign-share-ship-state-view(
            :promoter-campaign-share-ship="promoterCampaignShareShip"
          )
          .note.is-size-7
            template(v-if="promoterCampaignShareShip.state === 'registered'")
              | 您已成功報名此檔期，平台正在審核中
      k-field(
        style-type="kolcenter"
        :label="attributeLocaleText('promoter/campaign_share_ship', 'periods')"
        horizontal
      )
        .periods-detail.mb-3(v-if="promoterCampaign.has_periods")
          campaign-period-sales-time-range-view.is-size-7.mb-3(
            v-for="(promoterCampaignPeriod, index) in selectedPromoterCampaignPeriods"
            v-if="hasSelectedFor(promoterCampaignPeriod)"
            :key="promoterCampaignPeriod.id"
            :promoter-campaign-period="promoterCampaignPeriod"
            display-type="oneline"
            include-name
            name-type="is-ci"
            checked
          )
        .time-detail(v-else)
          campaign-sales-time-range-view(:promoter-campaign="promoterCampaign")

      .section(v-if="promoterCampaign.has_sample")
        .section-title 樣品
        k-field(
          style-type="kolcenter"
          :label="attributeLocaleText('promoter/campaign_sample_shipment', 'state')"
          horizontal
        )
          campaign-sample-shipment-state-view(
            :promoter-campaign-sample-shipment="promoterCampaignSampleShipment"
          )
        k-field(
          style-type="kolcenter"
          :label="attributeLocaleText('promoter/campaign_sample_shipment', 'contact_phone')"
          horizontal
        )
          span {{ promoterCampaignSampleShipment.contact_phone }}
        k-field(
          v-if="promoterCampaignSampleShipment.address"
          style-type="kolcenter"
          :label="attributeLocaleText('promoter/campaign_sample_shipment', 'address')"
          horizontal
        )
          k-address-view(:address="promoterCampaignSampleShipment.address")
        k-field(
          v-if="['ready', 'shipped', 'delivered'].includes(promoterCampaignSampleShipment.state)"
          style-type="kolcenter"
          :label="attributeLocaleText('promoter/campaign_sample_shipment', 'items')"
        )
          sample-shipment-item-list(
            :promoter-campaign-sample-shipment="promoterCampaignSampleShipment"
          )

    //- template(slot="action")
      //- k-button(
      //-   v-if="promoterCampaign.isOnRegistration()"
      //-   type="shadow-border"
      //-   @click="changePeriodsHandler"
      //- ) 調整時段
</template>

<script setup>
import { reactive, computed, onMounted } from 'vue'
import CampaignPeriodSalesTimeRangeView from '@sharedComponents/promoter_campaign_period/common/sales-time-range-view.vue'
import CampaignSalesTimeRangeView from '@sharedComponents/promoter_campaign/common/sales-time-range-view.vue'
import KFormCard from '../../common/k-form-card.vue'
import KField from '@sharedComponents/common/k-field.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import KAddressView from '@sharedComponents/common/k-address-view.vue'
import PromoterEventVariantShipListItem from '../../promoter_event_variant_ship/list-item.vue'
import CampaignShareShipStateView from '../../promoter_campaign_share_ship/state-view.vue'
import CampaignSampleShipmentStateView from '../../promoter_campaign_sample_shipment/state-view.vue'
import SampleShipmentItemList from '@sharedComponents/promoter_campaign_sample_shipment/common/item-list.vue'

import {
  useToast,
  useStore,
  useInstance,
  useModal,
  useI18n
} from 'skid-composables'

const props = defineProps({
  promoterShare: { type: Object, required: true },
  promoterCampaign: { type: Object, required: true }
})

const emit = defineEmits(['show-registration'])

const vueInstance = useInstance()
const store = useStore()
const data = reactive({
  promoterCampaignPeriodIds: []
})

const promoterCampaignShareShip = computed(() => {
  return store.getters['promoterCampaignShareShips/all'].find(
    (campaignShareShip) => {
      return (
        campaignShareShip.campaign_id === parseInt(props.promoterCampaign.id) &&
        campaignShareShip.share_id === parseInt(props.promoterShare.id)
      )
    }
  )
})

const promoterCampaignSampleShipment = computed(() => {
  return store.getters['promoterCampaignSampleShipments/findBy']({
    share_ship_id: parseInt(promoterCampaignShareShip.value.id)
  })
})

const promoterCampaignPeriods = computed(() => {
  return data.promoterCampaignPeriodIds.map((id) => {
    return store.getters['promoterCampaignPeriods/find'](id)
  })
})

const promoterCampaignPeriodShares = computed(() => {
  return store.getters['promoterCampaignPeriodShares/all'].filter((share) => {
    return share.share_ship_id === parseInt(promoterCampaignShareShip.value.id)
  })
})

const selectedPromoterCampaignPeriods = computed(() => {
  return promoterCampaignPeriods.value.filter((period) => {
    return hasSelectedFor(period)
  })
})

const unselectedPromoterCampaignPeriods = computed(() => {
  return promoterCampaignPeriods.value.filter((period) => {
    return !hasSelectedFor(period)
  })
})

const fetchPeriods = async () => {
  const response = await store.dispatch('promoterCampaigns/fetchPeriods', {
    model: props.promoterCampaign
  })

  data.promoterCampaignPeriodIds = response.data.data.map((row) => row.id)
}

const hasSelectedFor = (campaignPeriod) => {
  return promoterCampaignPeriodShares.value
    .map((campaignPeriodShare) => campaignPeriodShare.period_id)
    .includes(parseInt(campaignPeriod.id))
}

const changePeriodsHandler = () => {
  emit('show-registration')

  vueInstance.$parent.close()
}

const promoterEventVariantShipFor = (item) => {
  return store.getters['promoterEventVariantShips/find'](
    item.event_variant_ship_id
  )
}

const getPromoterCampaignSampleShipment = () => {
  return store.dispatch(
    'promoterCampaignSampleShipments/find',
    promoterCampaignSampleShipment.value.id
  )
}

onMounted(async () => {
  if (props.promoterCampaign.has_periods) {
    await fetchPeriods()
  }

  if (
    props.promoterCampaign.has_sample &&
    promoterCampaignSampleShipment.value
  ) {
    await getPromoterCampaignSampleShipment()
  }
})
</script>
